import { useFetch } from "../../hooks/useFetch";
import { URL, columnsModal } from "../../utils/constants";
import Table from '../Table/Table'
import { Loading } from "@nextui-org/react";
import { useTranslation } from "react-i18next";

function ModalContentDocumentUser({ userId, state }) {
  const { t , i18n } = useTranslation("global");
  const { data, loading } = useFetch(
    `${URL}user/${userId}/documents/${state}`
  );


  return (
    <div style={{overflowY:"auto"}}>
      {loading ? (
        <Loading />
      ) : (
        <Table
          columns={columnsModal[i18n.resolvedLanguage][state]}
          data={data}
          expandableRows={false}
        />
      )}
    </div>
  );
}

export default ModalContentDocumentUser;