import React from "react";
import Select from "../Select/Select";
import GeneralInput from "../Input/GeneralInput";
import { useTranslation } from "react-i18next";

const DocumentFilter = ({
  filterText,
  setFilterText,
  typeDate,
  setTypeDate,
  startDate,
  setStartDate,
  finishDate,
  setFinishDate,
}) => {
  const { t , i18n } = useTranslation("global");
  const handleClear = () => {
    if (filterText) {
      setFilterText("");
    }
  };

  const handleFilterText = (data) => {
    setFilterText(data);
  };

  const handleDatePickerStart = (data) => {
    setStartDate(data);
  };

  const handleDatePickerFinish = (data) => {
    if (startDate > data) {
      alert(t("DocumentFilter.Nosepuedeasignarunafechafinalmenorquelainicial"));
      return false;
    }
    setFinishDate(data);
  };

  const handleDateType = (data) => {
    setTypeDate(data);
  };

  return (
    <>
      <Select onSelect={handleDateType} />
      <div style={{paddingRight: "10px"}}>
        <p style={{textAlign: "center"}}>{t("DocumentFilter.Fechadeinicio")}</p>
        <GeneralInput
          id="start-date"
          type="date"
          onChange={handleDatePickerStart}
          onClear={handleClear}
        />
      </div>
      <div style={{paddingRight: "10px"}}>
        <p style={{textAlign: "center"}}>{t("DocumentFilter.FechaFinal")}</p>
        <GeneralInput
          id="finish-date"
          type="date"
          onChange={handleDatePickerFinish}
          onClear={handleClear}
        />
      </div>
      <GeneralInput
        id="filter"
        label={t("DocumentFilter.Buscar")}
        type="text"
        onChange={handleFilterText}
        onClear={handleClear}
      />
    </>
  );
};

export default DocumentFilter;
