import React from "react";
import { Dropdown, Text, Spacer } from "@nextui-org/react";
import { useTranslation } from "react-i18next";

function Select(props) {
  const { t , i18n } = useTranslation("global");
  const [selected, setSelected] = React.useState(new Set(["Seleccione una fecha"]));

  const selectedValue = React.useMemo(
    () => Array.from(selected).join(", ").replaceAll("_", " "),
    [selected]
  );

  const handleSelectionChange = (newSelection) => {
    setSelected(newSelection);
    props.onSelect(Array.from(newSelection));
  };

  return (
    <>
      <Dropdown >
        <Dropdown.Button light bordered color="default" css={{ tt: "capitalize" }}>
          {selectedValue}
        </Dropdown.Button>
        <Dropdown.Menu
          aria-label="Single selection actions"
          color="default"
          disallowEmptySelection
          selectionMode="single"
          selectedKeys={selected}
          onSelectionChange={handleSelectionChange}
        >
          <Dropdown.Item key={t("Select.Seleccioneunafecha")}>{t("Select.Seleccioneunafecha")}</Dropdown.Item>
          <Dropdown.Item key={t("Select.Fechadeenvio")}>{t("Select.Fechadeenvio")}</Dropdown.Item>
          <Dropdown.Item key={t("Select.Fechadefirmado")}>{t("Select.Fechadefirmado")}</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Spacer x={0.5} />
    </>
  );
}

export default Select;
