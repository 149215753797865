import React from "react";
import GeneralInput from "../Input/GeneralInput";
import { useTranslation } from "react-i18next";

const UserFilter = ({ filterText, setFilterText }) => {
  const { t , i18n } = useTranslation("global");
  const handleClear = () => {
    if (filterText) {
      setFilterText("");
    }
  };

  const handleFilterText = (data) => {
    setFilterText(data);
  };

  return (
    <GeneralInput
      id="filter"
      label={t("UserFilter.Buscar")}
      type="text"
      onChange={handleFilterText}
      onClear={handleClear}
    />
  );
};

export default UserFilter;
