import { useState } from "react";
import { Grid, Loading, Text , styled} from "@nextui-org/react";
import { useFetch } from "../../hooks/useFetch";
import { URL } from "../../utils/constants";
import GeneralCard from "../Cards/GeneralCard";
import GeneralModal from "../Modal/GeneralModal";
import ModalContentDocumentUser from "../ModalContent/ModalContentDocumentUser";
import logo from "../../assets/images/perfil.png";
import total from "../../assets/images/total-documentos.png";
import completados from "../../assets/images/documentos-completado.png";
import pendientes from "../../assets/images/documentos-pendientes.png";
import "./ExpandableTableUser.css";
import { useTranslation } from "react-i18next";

const StyledImg = styled("img", {
  width: "250px", height: "250px",
  borderRadius: "50%",
  border: "2px solid rgb(0, 108, 216)"
});
const StyledContainerImg = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems:"center"
});

function ExpandableTableUser({ data }) {
  const states = { completed: "Completados", pending: "Pendientes" };
  
  const [showModal, setShowModal] = useState(false);
  const [state, setState] = useState("");
  const { t , i18n } = useTranslation("global");

  const { data: userData, loading } = useFetch(
    `${URL}user/${data.usu_id}/details`
   
  );

  const { data: userDataImg, loadingImg } = useFetch(
    `${URL}image/${data.usu_docume}`
    
  );

  if (loading) {
    return <div><Loading></Loading></div>;
  }

  const handleClickCardCompleted = () => {
    setShowModal(true);
    setState("completed");
  };

  const handleClickCardPending = () => {
    setShowModal(true);
    setState("pending");
  };


  const closeModal = () => {
    setShowModal(false);
  };
 

  return (
    <>
      <Grid.Container className="grid-container">
        <div>
          
        {userDataImg?.image ? (
    <StyledImg
      className="icono"
      src={`data:image/png;base64, ${userDataImg.image}`}
      alt="Client Face"
    />
  ) : (
    <StyledImg className="icono" src={logo} alt="Logo" />
  )}

          
        </div>

        <div className="user-data">
          <p>{t("ExpandableTableUser.Resumen")}</p>
          <p>{t("ExpandableTableUser.Nombre")}: {`${data.usu_nombre} ${data.usu_apelli}`}</p>
          <p>{t("ExpandableTableUser.PlanActual")}</p>
        </div>

        <hr className="separator" />
        
        <div className="user-signature">
          <p><img src={total} /> {t("ExpandableTableUser.Totaldedocumentos")}: <span>{loading ? <Loading /> : userData.total_documents}</span></p>
          <p onClick={handleClickCardCompleted}><img src={completados} /> {t("ExpandableTableUser.Documentoscompletados")}: <span>{loading ? <Loading /> : userData.completed_documents}</span></p>
          <p onClick={handleClickCardPending}><img src={pendientes} /> {t("ExpandableTableUser.Documentospendientes")}: <span>{loading ? <Loading /> : userData.pending_documents}</span></p>
        </div>
      </Grid.Container>
      <GeneralModal
        title={t("ExpandableTableUser.Documentos ") + states[state]}
        size={'900px'}
        component={<ModalContentDocumentUser userId={data.usu_id} state={state} />}
        visible={showModal}
        onClose={closeModal}
      />
    </>
  );
}

export default ExpandableTableUser;
