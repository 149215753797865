import React from "react";
import PieChart from "../Charts/PieChart";
import BarChart from "../Charts/BarChart"
import CustomLineChart from "../Charts/LineChart";
import { styled, Card } from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import {  Switch  } from "@nextui-org/react";

const StyledCard = styled(Card, {
  minWidth: "600px",
  height: "100%",
  margin: "0.5rem",
});

function ChartsContainer({ topSenders, monthlyCounts, topSignedSenders }) {
  const { t , i18n } = useTranslation("global");

  monthlyCounts = Object.fromEntries(Object.entries(monthlyCounts).reverse());

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <StyledCard>
          <Card.Body>
            <BarChart data={topSenders} title={t('charts.Documentosenviados')} titleSlice={'info:'} ></BarChart>
          </Card.Body>
        </StyledCard>
        <StyledCard>
          <Card.Body>
            <PieChart data={topSignedSenders} title={t('charts.Consumosgenerales')} titleSlice={'info:'}></PieChart>
          </Card.Body>
        </StyledCard>
      </div>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <StyledCard>
          <Card.Body>
            <BarChart data={topSignedSenders} title={t('charts.Documentosfirmados')} titleSlice={'info:'}></BarChart>
          </Card.Body>
        </StyledCard>
        <StyledCard>
          <Card.Body>
            <PieChart data={topSignedSenders} title={t('charts.Otrosconsumos')} titleSlice={'info:'}></PieChart>
          </Card.Body>
        </StyledCard>
      </div>
      <StyledCard>
        <Card.Body>
          <CustomLineChart monthlyCounts={monthlyCounts} />
        </Card.Body>
      </StyledCard>
      
    </div>
  );
}

export default ChartsContainer;
