import {  Loading } from "@nextui-org/react";
import { useFetch } from "../../hooks/useFetch";
import { URL, columnsModal } from "../../utils/constants";
import Table from "../Table/Table";
import { useTranslation } from "react-i18next";

function ModalContentConsumption({ data }) {
    const { t , i18n } = useTranslation("global");
    const { data: userData, loading } = useFetch(
        `${URL}user/${data.user_id}/documents/completed`
    );

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                userData && <Table columns={columnsModal[i18n.resolvedLanguage].completed} data={userData} />)}
        </>
    );
}

export default ModalContentConsumption;
