import { Modal, Text, Button } from "@nextui-org/react";
import { useTranslation } from "react-i18next";
function GeneralModal({ title, size, component, visible, onClose }) {
  const { t , i18n } = useTranslation("global");
  return (
    <Modal
      scroll={true}
      open={visible}
      width={size}
      closeButton
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      onClose={onClose}
    >
      <Modal.Header>
        <Text id="modal-title" size={18}>
          {title}
        </Text>
      </Modal.Header>
      <Modal.Body>{component}</Modal.Body>
      <Modal.Footer>
        <Button flat auto color="error" onPress={onClose}>
          {t("GeneralModal.close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default GeneralModal;
